import React, { useState, useEffect, useContext } from "react";
import PageNav from '../../components/header/page-navbar';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { APP_TYPE, WebUrl } from "../../util/Constant";
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { AppConfiguration } from "read-appsettings-json";

/// <summary>
/// Author: Wong
/// </summary>
const Help = () => {
    var { t } = useTranslation();
    var _history = useHistory();

    const [activeTab, setActiveTab] = useState(0);
    const [filteredTab, setFilteredTab] = useState([]);

    const _HELP_PDF = [
        {
            name: t("BETTING_TUTORIAL"),
            file: [
                require("../../assets/pdf/jj-help1.png"),
                require("../../assets/pdf/jj-help2.png"),
                require("../../assets/pdf/jj-help3.png"),
                require("../../assets/pdf/tutorial-3.png"),
                require("../../assets/pdf/tutorial-4.png"),
                require("../../assets/pdf/tutorial-5.png"),
            ]
        },
        {
            hide: [APP_TYPE._NORMAL],
            name: "Dragon Jackpot 成龍積寳 6+1D", file: [
                require("../../assets/pdf/0007.jpg")
            ]
        },
        {
            hide: [APP_TYPE._NORMAL],
            name: "Bonus Jackpot 红利奖金池", file: [
                require("../../assets/pdf/0001.jpg"),
                require("../../assets/pdf/0002.jpg"),
                require("../../assets/pdf/0003.jpg"),
                require("../../assets/pdf/0004.jpg"),
                require("../../assets/pdf/0005.jpg"),
                require("../../assets/pdf/0006.jpg")
            ]
        }
    ];

    /// <summary>
    /// Author : Wong
    /// </summary>
    useEffect(() => {
        setFilteredTab(_HELP_PDF.filter(tab => tab.hide != null ? !tab.hide.includes(AppConfiguration.Setting().ApplicationConfig.appType) : true));
    }, []);

    return (
        <div>
            <PageNav pageTitle={t('HELP')} pageBackHandler={() => { _history.push(WebUrl._HOME) }} showDate={false} />
            <div className="set-page-content">
                <div className="page-container container flex-container">
                    <Nav className="brand-nav mb-2" tabs>
                        {
                            filteredTab.map((pdf, index) => {
                                return <NavItem>
                                    <NavLink
                                        key={index}
                                        className={classnames({ active: activeTab === index })}
                                        onClick={() => setActiveTab(index)}>
                                        {pdf.name}
                                    </NavLink>
                                </NavItem>
                            })
                        }
                    </Nav>
                    <TabContent className="tab-content-help p-0">
                        {
                            filteredTab.map((pdf, index) => {
                                return <div tabId={index} className={classnames("tab-pane-help", { "active-tab": activeTab === index })}>
                                    {
                                        pdf.file.map((img, index) => {
                                            return <img src={img} alt={index} style={{ width: '100%' }} />
                                        })
                                    }
                                </div>
                            })
                        }
                    </TabContent>
                </div>
            </div>
        </div>
    );
};

export default Help;